import * as THREE from "three";
import { TilesRenderer } from "3d-tiles-renderer";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { CSS2DRenderer, CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import axios from "@/http/HTTP";

var ModelLoader = function () {
  var renderOrder = 100;
  var curChild = [];
  var curChildObjectName = [];
  var tilesObjArr = [];
  var tilesRendererArr = [];
  var finishPer = 1;
  var objectTotal = {};
  this.init = function (
    mainRenderer,
    devicePixelRatio,
    innerWidth,
    innerHeight
  ) {
    //console.log("########### ModelLoader init:");
    mainRenderer.shadowMap.enabled = true;
    mainRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mainRenderer.sortObjects = true;
    mainRenderer.setPixelRatio(devicePixelRatio);
    mainRenderer.setSize(innerWidth, innerHeight);
  };

  this.initFbxModel = function (scene, modelPath, hideName,tag, childrenModel,floor) {
    // 模型的加载提示
    {
      var manager = new THREE.LoadingManager();
      manager.onProgress = function (item, loaded, total) {};
      var onProgress = function (xhr) {
        if (xhr.lengthComputable) {
          var percentComplete = (xhr.loaded / xhr.total) * 100;
          // console.log(Math.round(percentComplete, 2) + "% downloaded");
        }
      };
      var onError = function (xhr) {
        console.error("onError:", xhr); //错误提示
      };
    }

    var fbx_loader = new FBXLoader(manager);
    // console.log(modelPath);
    let that = this;
    var curChildT = [];
    fbx_loader.load(
      modelPath,
      function (object) {
        // console.log("ob:", object);
        var date = new Date();
        object.traverse(function (child) {
          // if (child.isMesh) {
          //   curChildT.push(child);
          //   childrenModel.push(child);
          //   //curChildObjectName.push(child.name);
          //   //that.$refs.renderViewRef.setChildList(child.name);
          //   //取出模型各子组件
          //   that.renderOrder += 10;
          //   child.renderOrder = that.renderOrder; //设置渲染的顺序，避免深度冲突
          //   child.castShadow = true;
          //   child.material.shadowSide = THREE.BackSide;
          //   child.material.side = 0;
          //   // child.material.emissive = child.material.color;
          //   // child.material.emissiveMap = child.material.map;
          //   child.geometry.computeVertexNormals();
          //   //console.log("child:", child.name, child);
          //   if (child.name.indexOf(hideName) != -1 && hideName != "") {
          //     child.visible = false;
          //   }
          // }
          child.castShadow = true;
          child.receiveShadow = true;
          if (child instanceof THREE.Mesh) {
            childrenModel.push(child);
            child.material.shadowSide = THREE.BackSide;
            child.material.side = 0;
            child.geometry.computeVertexNormals();
          }
        });
        // object.scale.multiplyScalar(0.6);
        object.rotateX(-Math.PI / 2);
        object.add(tag);//标签插入model组对象中
        object.name = floor;
        //加载模型
        scene.add(object);
        objectTotal = object;
      },
      onProgress,
      onError
    );
    return curChildT;
  };
  this.removeFbxModel = function (scene) {
    scene.remove(this.objectTotal);
  };
  this.addOrder = function () {
    renderOrder += 10;
    return renderOrder;
  };

  this.initTitleModel = function (
    modelPath,
    mainCamera,
    mainRenderer,
    mainScene,
    tilesArr = []
  ) {
    // 如果连接了后台服务器,使用下面jquery.js 的 getJSON函数
    $.getJSON(modelPath + "tileset.json").then((res) => {
      const tilesetArr = res.root.children;
      // console.log("********initTitleModel:", res.root.children);
      for (const tilese of tilesetArr) {
        var temp = tilese.content.uri;
        var regTemp = new RegExp("./");
        var dstPath = temp.replace(regTemp, "");
        var tilesRenderertemp = new TilesRenderer(modelPath + dstPath);
        // console.log("########### path:", modelPath + dstPath);
        // console.log("tilesRenderertemp:", tilesRenderertemp);
        tilesRenderertemp.setCamera(mainCamera);
        tilesRenderertemp.setResolutionFromRenderer(mainCamera, mainRenderer);
        const tilesObj = tilesRenderertemp.group;
        //test aad
        // const children = tilesObj.children;
        // console.log("tilesObj.children: " + children.length);
        // tilesObj.scale.set(2,2,2);//缩小为原来0.5倍
        // tilesObj.rotation.set(-Math.PI / 2, 0, Math.PI / 3);
        tilesObj.rotation.set(-Math.PI / 2, 0, 0);
        mainScene.add(tilesObj);
        tilesObjArr.push(tilesObj);
        tilesRendererArr.push(tilesRenderertemp);
        // tilesArr.push(tilesRenderertemp);
      }
    });
  };


  this.hideTitleModel = function (scene) {
    console.log("隐藏倾斜摄影模型hideTitleModel")
    if (tilesObjArr.length > 0) {
      for (let tilesObj of tilesObjArr) {
          tilesObj.visible = false;
          scene.remove(tilesObj);
      }
    }
  };
this.displayTitleModel= function (scene) {
  console.log("恢复倾斜摄影模型displayTitleModel")
  if (tilesObjArr.length > 0) {
    for (let tilesObj of tilesObjArr) {
        tilesObj.visible = true;
        scene.add(tilesObj);
    }
  }
};
  this.renderModel = function () {
    if (tilesRendererArr.length > 0) {
      for (const tilesRenderer of tilesRendererArr) {
        tilesRenderer.update();
      }
      // for (let i = 0; i < 100; i++) {
      //   tilesRendererArr[i].update();
      // }
    }
  };
  this.deleteModel = function (scene, myObjects) {
    console.log("this.deleteModel*****",myObjects.length);
    if (myObjects.length > 0) {
      for (var i = 0; i < myObjects.length; i++) {
        var currObj = myObjects[i];

        // 判断类型
        if (currObj instanceof THREE.Group) {
          var children = currObj.children;
          for (i = 0; i < children.length; i++) {
            this.deleteGroup(children[i]);
          }
        } else {
          this.deleteItem(currObj);
        }
        scene.remove(currObj);
      }
    }
  };
  this.deleteGroup = function (group) {
    if (!group) return;
    // 删除掉所有的模型组内的mesh
    group.traverse(function (item) {
      this.deleteItem(item);
    });
  };
  this.deleteItem = function (item) {
    if (item instanceof THREE.Mesh) {
      item.geometry.dispose(); // 删除几何体
      // for (var index = 0; index < item.material.length; ++index) {
      //   item.material[index].MeshPhongMaterial.dispose(); // 删除材质
      // }
    }
  };
};

export { ModelLoader };
